<template>
  <div class="member-search">
    <div class="wrapper">
      <div class="head">
        <div class="title">
          <span>회원 검색</span>
        </div>
      </div>
      <div class="body">
        <div class="input">
          <select class="form-control" v-model="state.args.key">
            <option value="memberId">아이디</option>
            <option value="memberName">이름</option>
            <option value="memberSeq">회원번호</option>
            <option value="corporateName">기업명</option>
          </select>
          <input type="text" class="form-control" placeholder="키워드를 입력해주세요" v-model="state.args.value" @keyup.enter="load(0)">
          <button class="btn btn-bordered-secondary" @click="load(0)"><i class="fa fa-search"></i></button>
        </div>
        <div class="table-responsive thin-scrollbar" v-if="state.members.length || !state.loaded" :class="{'skeleton': !state.loaded}">
          <table class="table table-hover">
            <thead>
            <tr>
              <th><span>구분</span></th>
              <th><span>회원번호</span></th>
              <th><span>아이디</span></th>
              <th><span>회원명/기업명</span></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="m in state.members" :key="m.memberSeq">
              <td>
                <span class="badge badge-secondary" v-if="m.memberType === 'P'">일반</span>
                <span class="badge badge-success" v-else-if="m.memberType === 'I'">개인</span>
                <span class="badge badge-primary" v-else-if="m.memberType === 'C'">법인</span>
              </td>
              <td>
                <span>{{ m.memberSeq }}</span>
              </td>
              <td @click="pick({memberId: m.memberId, memberName:m.memberName, memberSeq: m.memberSeq})" v-if="!m.assignDate">
                <span class="email color-anchor">{{ m.memberId }}</span>
              </td>
              <td v-else>
                <span class="badge badge-dark color-white mr-2">발급됨</span>
                <span>{{ m.memberId }}</span>
              </td>
              <td><span>{{ m.memberName }}</span></td>
            </tr>
            </tbody>
          </table>
          <PaginationJpa :pageable="state.pageable" :total="state.total" :change="load" v-if="state.total" className="btn-primary"/>
        </div>
        <NoData v-else/>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import PaginationJpa from "@/components/PaginationJpa.vue";
import http from "@/scripts/http";
import NoData from "@/components/NoData.vue";

function Component(initialize) {
  this.name = "modalMemberSearch";
  this.initialize = initialize;
}

export default defineComponent({
  components: {NoData, PaginationJpa},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "md"});
      model = getModel();
      load();
    });

    const modalParams = store.getters.modalParams(component);

    const state = reactive({
      loaded: false,
      args: {
        key: "memberId",
        value: "",
        page: 0,
        size: 100,
      },
      pageable: {
        size: 0,
        page: 0,
      },
      total: 0,
      members: []
    });

    let model = {
      memberId: "",
      memberName: "",
      memberSeq: "",
    };

    const load = (page) => {
      const args = {};

      args.page = page || state.args.page;
      args.size = state.args.size;
      state.args.value?.trim() && (args[state.args.key] = state.args.value);

      state.loaded = false;
      state.members = [];

      for (let i = 0; i < 5; i += 1) {
        state.members.push({
          memberSeq: "000000",
          memberName: "Wait a moment",
          memberId: "Please wait a moment",
          memberType: "P",
        });
      }
      http.get(`/api/admin/coupons/pools/${modalParams.couponId}/members`, args).then(({data}) => {
        state.loaded = true;
        state.members = data.body.content;
        state.total = data.body.total;
        state.pageable = data.body.pageable;
      });
    };

    const pick = (member) => {
      if (!Object.keys(model).includes("memberName")
          || !Object.keys(model).includes("memberId")
          || !Object.keys(model).includes("memberSeq")) {
        return console.warn("The model is not includes necessary type");
      }

      for (let i in model) {
        model[i] = member[i];
      }

      store.commit("closeModal", {
        name: component.name,
        onClose(modal) {
          store.dispatch("callback", {modal});
        }
      });
    };

    const getModel = () => {
      const names = modalParams.model?.split(".");

      if (names.length) {
        const component = store.getters.component(names[0]);

        if (component) {
          let model = component;

          for (let i = 1; i < names.length; i += 1) {
            model = model[names[i]];
          }

          return model;
        }
      }
    };

    return {component, state, pick, load};
  }
});
</script>

<style lang="scss" scoped>
.member-search {
  > .wrapper {
    padding: $px25;
    background: #fff;

    .head {
      margin-bottom: $px20;

      .title {
        font-size: $px18;
      }
    }

    .body {
      font-size: $px14;

      .input {
        display: flex;
        gap: $px8;

        select {
          width: fit-content;
        }

        input[type="text"] {
          flex-grow: 1;
        }
      }

      .table-responsive {
        margin-top: $px16;
        overflow: auto;

        .table tr {
          td {
            .email {
              cursor: pointer;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }

        &.skeleton {
          .table tr {
            th, td {
              span {
                @include skeleton;
              }
            }
          }
        }
      }
    }
  }
}
</style>